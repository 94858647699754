/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { loadStripe } from "@stripe/stripe-js"

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51HstDoH1Tu6HnYIa7lumHZoGqxTOVGTvYMTXxbV1tqg3XWQdGPJI4U8WhMzrkNQ5XDSFBsBUgUzukjYoLRmZlCCu00Gg0VK5iz"
    )
  }
  return stripePromise
}

export default getStripe
