import React, { useState } from "react"
import getStripe from "../../utils/stripejs"

const cardStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "flex-start",
  padding: "1rem",
  marginBottom: "1rem",
  boxShadow: "5px 5px 25px 0 rgba(46,61,73,.2)",
  backgroundColor: "#fff",
  // borderRadius: "6px",
  maxWidth: "400px",
}
const buttonStyles = {
  fontFamily: "arial",
  display: "block",
  fontSize: "13px",
  textAlign: "center",
  color: "#000",
  padding: "12px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  backgroundColor: "rgb(255, 178, 56)",
  borderRadius: "6px",
  letterSpacing: "1.5px",
}
const h4Styles = {
  fontSize: "24px",
  color: "#e41c94",
}
const descStyles = {
  fontSize: "16px",
  fontFamily: "arial",
}

const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
}

const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2)
  let numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })
  return numberFormat.format(price)
}

const ProductCard = ({ product }) => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)

    const price = new FormData(event.target).get("priceSelect")
    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      billingAddressCollection: "required",
      shippingAddressCollection: { allowedCountries: ["GB"] },
      lineItems: [{ price, quantity: 1 }],
      successUrl: "https://sites.google.com/view/prints-for-good/thanks",
      cancelUrl: "https://sites.google.com/view/prints-for-good/home",
    })

    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }

  return (
    <div style={cardStyles}>
      <form onSubmit={handleSubmit}>
        <fieldset style={{ border: "none" }}>
          <legend align="center">
            <h4 style={h4Styles}>{product.name}</h4>
          </legend>
          <img src={product.images} height="400px" alt="product"></img>
          <p align="center" style={descStyles}>
            {product.description}
            <br></br>
            <label>
              Price{" "}
              <select name="priceSelect">
                {product.prices.map(price => (
                  <option key={price.id} value={price.id}>
                    {formatPrice(price.unit_amount, price.currency)}
                  </option>
                ))}
              </select>
            </label>
          </p>
        </fieldset>
        <p align="center">
          <button
            disabled={loading}
            style={
              loading
                ? { ...buttonStyles, ...buttonDisabledStyles }
                : buttonStyles
            }
          >
            BUY ME
          </button>
        </p>
      </form>
    </div>
  )
}

export default ProductCard
